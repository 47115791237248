import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Account } from "../models/Account.model";
import { updateAccount } from "../firebase/firebase.functions";
import { useSelector } from "react-redux";
import { RootState } from "..";
import { calculateDifferenceBetweenTwoTimeStamps } from "../utils/functions";
import moment from "moment";

interface Props {
  account: Account;
  docIndex: number;
}

const AccountCard = (props: Props) => {
  const account = props.account;

  const classes = useStyles(account.isInUse);

  const [timeDiff, setTimeDiff] = useState("");

  const userName = useSelector((state: RootState) => state.userName);
  const accounts = useSelector((state: RootState) => state.accounts);

  useEffect(() => {
    setTimeDiff(calculateDifferenceBetweenTwoTimeStamps(account.timestamp));
    if (account.timestamp) {
      var updateMinutesInterval = setInterval(() => {
        setTimeDiff(calculateDifferenceBetweenTwoTimeStamps(account.timestamp));
      }, 1000 * 30);
    }
    return () => {
      clearInterval(updateMinutesInterval);
    };
  }, [account, accounts]);

  const handleButtonClicked = () => {
    const now = moment(Date.now()).toString();

    const newAccountObj = {
      account: account.account,
      isInUse: !account.isInUse,
      user: account.isInUse ? "" : userName,
      timestamp: account.isInUse ? "" : now,
    };

    updateAccount(newAccountObj, props.docIndex);
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography className={classes.account} color="textSecondary" gutterBottom>
          Account
        </Typography>

        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {account.account}
        </Typography>
      </CardContent>

      <CardActions className={classes.actions}>
        {account.isInUse && (
          <Typography color="textSecondary" className={classes.description}>
            <strong>{account.user}</strong> started using this account <strong>{timeDiff} ago.</strong>
          </Typography>
        )}
        <Button fullWidth size="medium" onClick={handleButtonClicked}>
          {account.isInUse ? "RELEASE" : "USE"}
        </Button>
      </CardActions>
    </Card>
  );
};

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    minHeight: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: "1px solid #DEDEDE",
    margin: 15,
  },
  content: {
    padding: "18px 28px",
    borderBottom: "1px solid #DEDEDE",
  },
  account: {
    color: "#A7A7A7",
    fontFamily: "Montserrat-SemiBold",
    paddingTop: 10,
    paddingLeft: 10,
    marginBottom: 0,
  },
  title: {
    color: "#414D67",
    fontSize: 24,
    fontFamily: "Montserrat-SemiBold",
    paddingLeft: 10,
    marginBotton: 0,
  },
  actions: {
    justifyContent: "center",
    padding: "24px 34.5px",
    display: "flex",
    flexDirection: "column",
    "& p": { display: "inline-block", width: 306 },
    "& button": {
      margin: "0 !important",
      background: (isInUse: boolean) => (isInUse ? "#983732" : "transparent"),
      border: (isInUse: boolean) => `2px solid ${isInUse ? "transparent" : "#983732"}`,
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        background: (isInUse: boolean) => (isInUse ? "#983732" : "transparent"),
      },
      width: 306,
      height: 48,
      borderRadius: 6,
    },
    "& span": {
      fontWeight: "bold",
      color: (isInUse: boolean) => (isInUse ? "white" : "#983732"),
    },
  },
  description: {
    color: "#A7A7A7",
    marginBottom: 33,
    "& strong": {
      color: "#818181",
    },
  },
});

export default AccountCard;
