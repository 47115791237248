import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { logout } from "../firebase/firebase.functions";
import { useSelector } from "react-redux";
import { RootState } from "..";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import moveo_logo from "../assets/icons/moveo_logo.svg";

const Header: React.FC = () => {
  const classes = useStyles();
  const userName = useSelector((state: RootState) => state.userName);
  const windowWidth = useSelector((state: RootState) => state.windowWidth);

  const renderExitButton = (): JSX.Element => (
    <Button color="inherit" onClick={logout}>
      <ExitToAppIcon />
    </Button>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {windowWidth > 700 && <img src={moveo_logo} className={classes.logo} alt="moveo logo" />}

          <Typography variant="h6" className={classes.title}>
            Moveo’s Webflow Tracker.
          </Typography>

          {windowWidth > 700 && <Typography>{userName}</Typography>}
          {renderExitButton()}
        </Toolbar>
      </AppBar>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minHeight: 78,
      "& *": {
        fontFamily: "Montserrat",
      },
    },
    appBar: {
      overflow: "hidden",
    },
    toolbar: {
      padding: 0,
    },
    logo: { marginLeft: 44 },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      fontFamily: "Montserrat-SemiBold",
      textAlign: "left",
      marginLeft: 20,
    },
  })
);

export default Header;
