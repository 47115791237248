import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AccountCard from "./AccountCard";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { RootState } from "..";
import { Account } from "../models/Account.model";

const AccountsGrid: React.FC = () => {
  const accounts = useSelector((state: RootState) => state.accounts);
  const windowWidth = useSelector((state: RootState) => state.windowWidth);
  const classes = useStyles(windowWidth);

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h5" color="textSecondary" gutterBottom>
        Choose an account
      </Typography>

      <Grid container className={classes.grid}>
        <Grid item xs={12}>
          <Grid container justify="center">
            {accounts.map((account: Account, index: number) => (
              <Grid key={account.account} item>
                <AccountCard account={account} docIndex={index} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
    },
    grid: {
      flexGrow: 1,
      alignItems: "center",
      overflowX: "hidden",
      width: "92vw",
    },
    title: {
      color: "#414D67",
      fontFamily: "Montserrat-SemiBold",
      marginTop: (windowWidth) => (windowWidth > 700 ? 61 : 11),
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
  })
);

export default AccountsGrid;
