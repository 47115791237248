import firebase from "firebase";
import { firebase_app } from "./firebase.config";
import { store } from "..";
import { setAccounts, setLoggedInProperty, setUserName } from "../redux/actions/actions";
import { Account } from "../models/Account.model";

const dbRef = firebase.database().ref();

export function login() {
  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase_app.auth().signInWithPopup(provider);
  } catch (error) {
    console.log(error);
  }
}

export function logout() {
  try {
    firebase.auth().signOut();
  } catch (error) {
    console.error("Sign Out Error", error);
  }
}

export function fetchAccounts() {
  dbRef.child("accounts").on("value", (snapshot) => {
    snapshot.exists() ? store.dispatch(setAccounts(snapshot.val())) : console.log("No data available");
  });
}

export function updateAccount(account: Account, docIndex: number) {
  dbRef.child(`accounts/${docIndex}`).set(account, (error) => {
    if (error) {
      console.log("error");
    } else {
      console.log("saved successfully");
    }
  });
}

firebase_app.auth().onAuthStateChanged(function (user: any) {
  if (user) {
    store.dispatch(setUserName(user.email || ""));
    store.dispatch(setLoggedInProperty(true));
    fetchAccounts();
  } else {
    store.dispatch(setUserName(""));
    store.dispatch(setLoggedInProperty(false));
  }
});
