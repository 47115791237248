import { createMuiTheme } from "@material-ui/core/styles";

export const MoveoPalette = createMuiTheme({
  palette: {
    primary: {
      main: "#E5E5E5",
    },
    secondary: {
      main: "#983732",
    },
  },
});
