import moment from "moment";

export function calculateDifferenceBetweenTwoTimeStamps(startTimeStamp: string) {
  const now = moment.unix(Date.now() / 1000);
  const start = moment(startTimeStamp);
  const minutesDif = now.diff(start, "minutes");
  if (minutesDif < 60) return `${minutesDif} minutes`;

  return `${Math.round(minutesDif / 60)} hours`;
}
