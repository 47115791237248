import {
  ActionTypes,
  SET_LOGGED_IN_PROPERTY,
  SET_USER_NAME,
  SET_ACCOUNTS,
  SET_WINDOW_WIDTH,
} from "./ActionTypes";
import { Account } from "../../models/Account.model";

export const setLoggedInProperty = (isUserLoggedIn: boolean): ActionTypes => {
  return {
    type: SET_LOGGED_IN_PROPERTY,
    isUserLoggedIn,
  };
};

export const setUserName = (userName: string): ActionTypes => {
  return {
    type: SET_USER_NAME,
    userName,
  };
};

export const setAccounts = (accounts: Account[]): ActionTypes => {
  return {
    type: SET_ACCOUNTS,
    accounts,
  };
};

export const setWindowWidth = (windowWidth: number): ActionTypes => {
  return {
    type: SET_WINDOW_WIDTH,
    windowWidth,
  };
};
