import { Account } from "../../models/Account.model";

export const SET_LOGGED_IN_PROPERTY = "SET_LOGGED_IN_PROPERTY";
export const SET_USER_NAME = "SET_USER_NAME";
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const SET_WINDOW_WIDTH = "SET_WINDOW_WIDTH";

export interface SetLoggedInProperty {
  type: typeof SET_LOGGED_IN_PROPERTY;
  isUserLoggedIn: boolean;
}

export interface SetUserName {
  type: typeof SET_USER_NAME;
  userName: string;
}

export interface SetAccounts {
  type: typeof SET_ACCOUNTS;
  accounts: Account[];
}
export interface SetWindowWidth {
  type: typeof SET_WINDOW_WIDTH;
  windowWidth: number;
}

export type ActionTypes = SetLoggedInProperty | SetUserName | SetAccounts | SetWindowWidth;
