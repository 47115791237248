import "./App.scss";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./index";
import AccountsGrid from "./components/AccountsGrid";
import Header from "./components/Header";
import HomeScreen from "./components/HomeScreen";
import { setWindowWidth } from "./redux/actions/actions";

function App() {
  const dispatch = useDispatch();

  const isUserLoggedIn = useSelector((state: RootState) => state.isUserLoggedIn);
  const windowWidth = useSelector((state: RootState) => state.windowWidth);

  dispatch(setWindowWidth(window.innerWidth));

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  const handleWindowResize = () => {
    dispatch(setWindowWidth(window.innerWidth));
  };

  const renderUserLoggedInComponents = (): JSX.Element => {
    return (
      <>
        <Header />
        <AccountsGrid />
      </>
    );
  };

  return <div className="App">{isUserLoggedIn ? renderUserLoggedInComponents() : <HomeScreen />}</div>;
}

export default App;
