import {
  ActionTypes,
  SET_LOGGED_IN_PROPERTY,
  SET_USER_NAME,
  SET_ACCOUNTS,
  SET_WINDOW_WIDTH,
} from "../actions/ActionTypes";
import { Account } from "../../models/Account.model";

/* eslint-disable import/no-anonymous-default-export */
interface Reducer {
  isUserLoggedIn: boolean;
  userName: string;
  accounts: Account[];
  windowWidth: number;
}

const INITIAL_STATE: Reducer = {
  isUserLoggedIn: false,
  userName: "",
  accounts: [],
  windowWidth: 0,
};

export default (state: Reducer = INITIAL_STATE, action: ActionTypes): typeof INITIAL_STATE => {
  switch (action.type) {
    case SET_LOGGED_IN_PROPERTY: {
      return {
        ...state,
        isUserLoggedIn: action.isUserLoggedIn,
      };
    }
    case SET_USER_NAME: {
      return {
        ...state,
        userName: action.userName,
      };
    }
    case SET_ACCOUNTS: {
      return {
        ...state,
        accounts: action.accounts,
      };
    }
    case SET_WINDOW_WIDTH: {
      return {
        ...state,
        windowWidth: action.windowWidth,
      };
    }
    default:
      return state;
  }
};
