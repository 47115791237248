import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import moveo_logo from "../assets/icons/moveo_logo.svg";
import google_icon from "../assets/icons/google_icon.svg";
import { login } from "../firebase/firebase.functions";
import Button from "@material-ui/core/Button";

const HomeScreen: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        Moveo's Webflow Tracker.
      </Typography>

      <img src={moveo_logo} className={classes.logo} alt="moveo logo" />

      <Button className={classes.login} color="inherit" onClick={login}>
        <img src={google_icon} alt="google icon" />
        Sign in with google
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    },
    title: {
      textAlign: "center",
      color: "#414D67",
      fontWeight: 700,
      marginBottom: 71,
      fontFamily: "Montserrat-Bold",
    },
    logo: {
      position: "absolute",
      top: 27,
      left: 27,
    },
    login: {
      color: "#414D67",
      padding: "18px 40px",
      borderRadius: 6,
      border: "2px solid rgb(65, 77, 103)",
      "& *": {
        fontFamily: "Montserrat-SemiBold",
      },
      "& img": {
        marginRight: 10.5,
      },
    },
  })
);

export default HomeScreen;
