import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AnyAction, compose, createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk, { ThunkAction } from "redux-thunk";

import { ActionTypes } from "./redux/actions/ActionTypes";
import reducer from "./redux/reducers/reducer";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { MoveoPalette } from "./utils/materialUi.theme";

export type RootState = ReturnType<typeof reducer>;
export type AppThunkAction = ThunkAction<Promise<void>, RootState, Record<string, unknown>, AnyAction>;
const composeEnhancers = (window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose) || compose;

export const rootReducer = (state: RootState | undefined, action: ActionTypes) => {
  return reducer(state, action);
};
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MuiThemeProvider theme={MoveoPalette}>
        <App />
      </MuiThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
